<template>
  <v-container class="pa-0" fluid>
    <page-title :heading="$t('erp.lang_mealType')"
                :subheading="$t('erp.lang_createMealTypeName')"
                :icon="icon"
                show-previous-button
    ></page-title>

    <div class="app-main__inner">
      <meal-type-create-component/>
    </div>
  </v-container>
</template>

<script>
import PageTitle from "../../../../Layout/Components/PageTitle";
import MealTypeCreateComponent from "../../../../components/erp/settings/mealType/MealTypeCreateComponent";

export default {
  name: "AllergensCreate",
  components: {MealTypeCreateComponent, PageTitle},
  data: () => ({
    icon: 'pe-7s-tools icon-gradient bg-tempting-azure',
  })
}
</script>

<style scoped>

</style>
