<template>
  <v-container fluid>
    <v-card elevation="0" outlined>
      <v-card-title><H5>{{$t('erp.lang_createMealTypeName')}}</H5></v-card-title>
      <v-divider class="pa-0 ma-0"/>
      <v-card-text>
        <v-row align="center" align-content="center" justify="center" no-gutters >
          <v-col cols="12" md="6" sm="10">
            <v-text-field outlined
                          @focus="showTouchKeyboard"
                          :data-layout="KEYBOARD.KEYSETS.NORMAL"
                          v-model="name"
                          :label="$t('erp.lang_mealTypeName')"
                          autocomplete="off"
                          required
            ></v-text-field>
            <fontawesome-picker  dense :field-label="$t('generic.lang_icon')" v-model="icon" type="meal" :rules="[v=>v&&!!v || $t('generic.lang_requiredField')]"/>

            <v-btn color="primary"
                   class="ma-0"
                   :disabled="name.length < 1 && icon.length < 1 "
                   :loading="loading"
                   @click="addData"
                   block large>
              {{$t('generic.lang_add') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard id="onScreenKeyboard" class="internalWidthExpanded" :options="touchKeyboard.options"
                          :defaultKeySet="touchKeyboard.keySet" v-if="touchKeyboard.visible"
                          :layout="touchKeyboard.layout" :cancel="hideTouchKeyboard"
                          :accept="hideTouchKeyboard" :input="touchKeyboard.input"/>
    </div>
  </v-container>
</template>

<script>
import {ENDPOINTS} from '../../../../config'
import {Events} from "../../../../plugins/events";
import mixin from '../../../../mixins/KeyboardMixIns';
import swatches from "vue-swatches"
import FontawesomePicker from "@/components/common/iconPicker";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {fal} from '@fortawesome/pro-light-svg-icons';
import {fas} from '@fortawesome/pro-solid-svg-icons';
import {library} from '@fortawesome/fontawesome-svg-core';
library.add(
    fal,fas
)

export default {
  components: {
    swatches,
    FontawesomePicker,
    "font-awesome-icon":FontAwesomeIcon,
  },
  mixins:[mixin],
  data(){
    return{
      ENDPOINTS,
      loading: false,
      icon : "" ,
      name  : ""
    }
  },
  methods:{
    addData: function () {
      this.loading=true;
      this.axios.post(ENDPOINTS.ERP.MEALTYPE.CREATE, {
        name: this.name,
        icon: this.icon
      }).then((res) => {
        if (res.data.status === 'SUCCESS') {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_addedSuccessfully'),
            color: "success"
          });
          this.loading=false;
          this.$router.push('/erp/settings/mealType');


        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
          self.loading=false;
        }
      }).catch(() => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
        self.loading=false;
      });
    },
  },
}
</script>

<style scoped>

</style>